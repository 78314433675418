.label-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    transition: background-color 0.5s ease;
}

.label-row:hover {
    cursor: pointer;
}



.regina {
    background-color: rgba(222, 184, 135, 0.5);
}

.regina:hover {
    background-color: rgba(222, 184, 135, 1);
}

.label-attendees {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

.label-menu {
    padding-left: 5px;
    display: none;
    background-color: rgba(222, 184, 135, 0.2);
}

.label-menu li {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

.showLabelMenu {
    display: flex;
    flex-direction: column;
}

.label-dropdown .pplUp {
    padding-left: 10px;
}

.hide-city-label {
    display: none;
}

.court-icon-container {
    display: inline-block;
}

.attendees-sm {
    display: none;
}

.attendees-lg {
    display: flex;
    padding-top: 5px;
    padding-bottom: 0;
    padding-right: 3px;
    
}

.label-dropdown {
    display: none;
}

@media screen and (min-width: 1500px) {
    .label-row {
        flex-direction: row;
    }

    .attendees-sm {
        display: flex;
    }

    .attendees-lg {
        display: none;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.friends-sm {
    padding-top: 3px;
}