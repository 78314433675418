/* Flex Classes */
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}

.flex-1 {
    flex: 1; /* */
}

.items-center {
    align-items: center;
    justify-content: center;
}

.justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

/* CSS Grid Classes */

.grid {
    display: grid; /* Allows you to create grid layouts */
}

.grid-cols-5 {
    /* uses the repeat() function to create seven columns, each with a minimum width of 0 and a maximum 
    width of 1fr (which means each column will take up an equal amount of available space)*/
    grid-template-columns: repeat(5, minmax(0, 1fr)); 
}

.grid-rows-5 {
    /* uses the repeat() function to create five rows, each with a minimum height of 0 and a maximum height
    of 1fr (which means each row will take up an equal amount of available space) */
    grid-template-rows: repeat(5, minmax(0, 1fr)); 
}

/* Calendar Styles */
.border {
    border-width: 1px;
    border-style: solid;
    border-color: currentColor;
}

.border-gray-200 {
    border-color: #e2e8f0;
}

.rounded {
    border-radius: 0.25rem;
}

/* Text Styling */
.text-sm {
    font-size: 0.875rem;
}

.text-center {
    text-align: center;
}

.text-xl {
    font-size: 1.25rem;
}

.text-gray-500 {
    color: #a0aec0;
}

.text-gray-600 {
    color: #718096;
}

.font-bold {
    font-weight: 700;
}

/* Spacing */
.mt-1 {
    margin-top: 0.25rem;
}

.p-1 {
    padding: 0.25rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.mr-10 {
    margin-right: 2.5rem;
}

/* Current Day */
.bg-blue-600 {
    background-color: #2563eb;
}

.text-white {
    color: #fff;
}

.rounded-full {
    border-radius: 9999px;
}

.w-7 {
    width: 1.75rem;
}

/* Calendar Header */
.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
}

.cursor-pointer {
    cursor: pointer;
}

/* Modal */
.h-screen {
    height: 100vh;
  }

  .w-full {
    width: 100%;
  }

  .fixed {
    position: fixed;
  }

  .left-0 {
    left: 0;
  }

  .top-0 {
    top: 0;
  }

  .bg-white {
    background-color: #fff;
  }

  .rounded-lg {
    border-radius: 0.5rem;
}

.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .w-14 {
    width: 25%;
  }

  .bg-gray-100 {
    background-color: #f7fafc;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
  }

  .text-gray-400 {
    color: #cbd5e0;
  }

  /* Event Modal Continued */
  .p-3 {
    padding: 0.75rem;
  }
  
  .grid {
    display: grid;
  }
  
  .grid-cols-15 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column: span 1 / span 5;
  }
  
  .items-end {
    align-self: end;
  }
  
  .gap-y-7 > * {
    --bs-gutter-y: 1.75rem;
    margin-top: calc(var(--bs-gutter-y) * 0.5);
    margin-bottom: calc(var(--bs-gutter-y) * 0.5);
  }
  
  .pt-3 {
    padding-top: 0.75rem;
  }

  .border-0 {
    border: 0;
    }
    
    .text-gray-600 {
    color: #718096;
    }
    
    .text-xl {
    font-size: 1.25rem;
    }
    
    .font-semibold {
    font-weight: 600;
    }
    
    .pb-2 {
    padding-bottom: 0.5rem;
    }
    
    .w-full {
    width: 100%;
    }
    
    .border-b-2 {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }
    
    .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: #e5e7eb;
    }

    .focus:focus {
        outline: none;
        box-shadow: none;
        border-width: 2px;
        border-style: solid;
        border-color: #3b82f6;
    }

    .text-gray-400 {
        color: #cbd5e0;
      }
      
      .gap-x > * + * {
        --bs-gutter-x: 1rem;
        margin-left: calc(var(--bs-gutter-x) * 1);
      }
      
      .w-6 {
        width: 1.5rem;
      }
      
      .h-6 {
        height: 1.5rem;
      }
      
      .rounded-full {
        border-radius: 9999px;
      }
      
      .cursor-pointer {
        cursor: pointer;
      }
      
      .text-white {
        color: #fff;
      }
      
      .text-sm {
        font-size: 0.875rem;
      }
      
      .bg-indigo-500 {
        --tw-bg-opacity: 1;
        background-color: #4f46e5;
        background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
      }
      
      .bg-blue-500 {
        --tw-bg-opacity: 1;
        background-color: #3b82f6;
        background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
      }
      
      .bg-green-500 {
        --tw-bg-opacity: 1;
        background-color: #10b981;
        background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
      }
      
      .bg-gray-500 {
        --tw-bg-opacity: 1;
        background-color: #6b7280;
        background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
      }
      
      .bg-red-500 {
        --tw-bg-opacity: 1;
        background-color: #ef4444;
        background-color: rgba(239,
        68, 68, var(--tw-bg-opacity));
    }
    
    .bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: #8b5cf6;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
    }

    /* Modal Footer */
    .border-t {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(209, 213, 219, var(--tw-border-opacity));
      }

      .p-3 {
        padding: 0.75rem;
      }

      .mt-5 {
        margin-top: 1.25rem;
      }

      .bg-blue-500 {
        --tw-bg-opacity: 1;
        background-color: #3b82f6;
        background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
      }

      .eventSubmit:hover {
        --tw-bg-opacity: 1;
  background-color: #2563eb;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
      }

      .px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      .rounded {
        border-radius: 0.25rem;
      }

      .text-white {
        color: #fff;
      }

 

/* -------------------------------- */
/* Calendar Row */
/* -------------------------------- */
/* Day Selected */
.indDay {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 0px 7px 2px rgba(0, 0, 0, 0.1);

}


.calendar-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #f8f8f8;
}

.calendarContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 0;
    flex-grow: 1;
}

.filterWidgetContainer {
    margin-top: 20px;
    padding-top: 0;
    margin-left: 20px;
    width: 400px;
}

.mobileFilterWidgetContainer {
  box-sizing: border-box;
  margin-top: 20px;
  padding-top: 0;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}

/* Calendar Header */
.calendarHdr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
}

.todayButton {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    margin-left: 0;
    line-height: 26px;
    border-radius: 5px;
    padding: 7px 25px;
}

.todayButton:hover {
    background-color: #4c5a74;
}

.monthTitle {
    color: #383838;
    font-family: 'Oxygen', sans-serif;
    font-size: 26px;
}

.calNavContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.calNavBtn {
    display: flex;
    margin: 0;
    padding: 6px 12px;
}

.calNavBtn:hover {
    background-color: #4c5a74;
}

.calPrevBtn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.calNextBtn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.calNavTxt {
    color: #ffffff;
    padding: 0;
    margin: 0;
    font-size: 25px;
}


/* Day Row at the top*/
.dayCol {
    background-color: #A48739;
}

.dayCol p {
    text-align: right;
    padding: 6px 8px 6px 0;
    color: #ffffff;
    font-size: 14px;
}

.monthContainer {
  height: auto;
}

.dayWrapper {
  background-color: #ffffff;
  height: 100px;
}

@media screen and (min-width: 900px) {
  .monthContainer {
    height: calc(100vh - 277px);
    border: 1px solid #383838;
    border-top: none;
  }

  .dayWrapper {
    background-color: #ffffff;
    height: auto;
  }
}

/* Styling the Calendar */




.calDD {
    text-align: right;
    padding-right: 7px;
    padding-top: 5px;
}

.currentDay {
    align-self: flex-end;
    margin-right: 4px;
    margin-top: 5px;
    font-weight: bold;
    color: blue;
}

.dayOffMonth {
    color: #aba9a9;
}

/* Selected Day */
.label-container {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* For WebKit-based browsers */
.label-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.label-container::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

.label-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.label-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovering */
}

/* For Firefox */
.label-container {
  scrollbar-color: #888 #f5f5f5; /* Thumb and track color */
  scrollbar-width: thin; /* Width of the scrollbar, can be 'auto', 'thin', or 'none' */
}

/* ===================================== */
/* Event Modal */

/* Container for the Attendees and Add Event Buttons*/
.event-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.event-w {
  width: 450px;
}

/*.form-toggle-container {
  display: none;
}*/

/* Attendee Button and Add Court Time Button */
.attendees-btn {
  margin-right: 0;
  width: 50%;
  margin-left: 40px;
  margin-right: 2px;
}

.addCourtTime-btn {
  margin-left: 0;
  width: 50%;
  margin-left: 2px;
  margin-right: 40px;
}

.attendees-btn, .addCourtTime-btn {
  background-color: #ffffff;
  color: #383838;
  border: 1px solid #a48544;
  font-family: 'PT Sans', sans-serif;
  border-radius: 5px;
}

.attendees-btn:hover, .addCourtTime-btn:hover {
  background-color: #4c5a74;
  color: #ffffff;
  cursor: pointer;
}

/* Active Event Button*/
.active-event-modal-btn {
  background-color: #a48544;
  color: #ffffff;
}

.active-event-modal-btn:hover {
  cursor: default;
  background-color: #a48544;
}

.modal-save-btn {
  border-radius: 5px;
  margin-bottom: 15px;
  font-family: 'PT Sans', sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-save-btn:hover {
  background-color: #4c5a74;
}

/* Event Modal Body */
.event-modal-body {
  margin-left: 40px;
  margin-right: 40px;
}

/* Attendee Menu */
.event-menu {
  display: none;
}

.event-menu li {
  padding-left: 20px;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
}

.showReginaAttendeeMenu {
  display: flex;
  flex-direction: column;
}

.showSaskatoonAttendeeMenu {
  display: flex;
  flex-direction: column;
}

.attendee-wrapper {
  margin-bottom: 20px;
}

/* Event Submenu */
.event-subheader-wrapper {
  display: flex;
  flex-direction: column;
}

.event-subheader-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
}

.event-subheader-row:hover {
  cursor: pointer;
}



.addEvent-submenu {
  display: none;
}

.addEvent-submenu li {
  padding-left: 20px;
}

.addEvent-submenu li div h5 {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
}

.addEvent-submenu li div p {
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
}

.showSaskatoonKB, .showSaskatoonPC .showReginaKB, .showReginaPC {
  display: flex;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.hideModal {
  display: none;
}

.showSaskatoonPC {
  display: flex;
}

.showSaskatoonAttendeeMenu {
  display: flex;
}

.noAttendees {
  padding-bottom: 30px;
}

.eventModalClose {
  padding-top: 3px;
  color: #ffffff;

}

.eventModalClose:hover {
  color: #383838;
}

.event-modal-overlay {
  z-index: 1000;
}

.sum-attendees-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  padding-bottom: 3px;
}

.sum-attendees-container:hover {
  cursor: pointer;
}

.sum-attendees-count {
  color:rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.attendee-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
}

.attendee-city-heading {
  font-size: 18px;
  font-weight: bold;
}

.attendee-date-heading {
  font-size: 20px;
  font-weight: bold;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pad-r {
  padding-right: 8px;
}

.mini-font {
  font-size: 13px;
  padding-right: 0;
}

@media screen and (min-width: 1500px) {
  .mini-font {
    padding-right: 8px;
  }
}