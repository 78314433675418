.fa-times:hover {
    cursor: pointer;
}

.hide {
    color: #e9e9e9;
}

.hide:hover {
    cursor: default;
}

/* Modal Overlay */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000 !important;
}

/* Modal Container */


.modal-header, .modal-footer {
    padding: 10px;
}

.modal-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}



.modal-title {
    margin: 0;
}

.modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    
    margin-left: 40px;
    margin-right: 40px;
}

.modal-footer-btn {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
    font-family: 'PT Sans', sans-serif;
    padding-top: 10px;
    padding-bottom: 10px;
}

.modal-footer-btn:hover {
    background-color: #4c5a74;
}

.form-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loginButton {
    margin-right: 0;
    width: 50%;
    margin-left: 40px;
    margin-right: 2px;
}

.registerButton {
    margin-left: 0;
    width: 50%;
    margin-left: 2px;
    margin-right: 40px;
}

.loginButton, .registerButton {
    background-color: #ffffff;
    color: #383838;
    border: 1px solid #a48544;
    font-family: 'PT Sans', sans-serif;
    border-radius: 5px;
}

.loginButton:hover, .registerButton:hover {
    background-color: #4c5a74;
    color: #ffffff;
    cursor: pointer;
}

.active-modal-btn {
    background-color: #a48544;
    color: #ffffff;
}

.active-modal-btn:hover {
    cursor: default;
    background-color: #a48544;
}

.modal-title {
    padding-top: 10px;
    padding-bottom: 20px;
}





.selectInput:placeholder {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

.inputField::placeholder {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

#provinces::placeholder {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

label {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
}

.modal-context-link {
    color: #0000EE;
    text-decoration: underline;
    
}

.modal-context-link:hover {
    cursor: pointer;
}

.form-toggle-container, .modalInterlink {
    display: none;
}

.modal-container {
    background-color: #e9e9e9;
    border-radius: 10px;
    min-width: 100vw;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-body {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.inputField {
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.modal-input {
    width: 100%;
}

.selectInput {
    margin-bottom: 15px;
    padding: 8px 5px 8px 5px;
    width: 100%;
}

.input-container {
    width: 100%;
}

@media screen and (min-width: 450px) {
    

    .modal-body {
        padding: 10px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .modal-container {
        width: 500px;
        min-width: 450px;
        max-width: 700px;
        max-height: 100vh;
    }
}

/* Loading Animation */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(187, 187, 187);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
      }
}