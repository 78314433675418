/* Ensuring the button is snug to the far right of the page */
#desktopMenu li:last-child {
    margin-right: 0;
}

/* Dropdown Buttons */
.dropdown-btn {
    border-radius: 5px;
    padding: 0px 30px;
    background-color: #c4b598;
    color: #ffffff;
    font-size: 16px;
    line-height: 36.1px;
}

.dropdown-btn:hover {
    color: #383838;
}

.fa-angle-down {
    position: relative;
    padding-left: 10px;
    top: 3px;
    -webkit-text-stroke: 0.5px #c4b598;
}

/* Dropdown menu */
.dropdown-menu {
    position: absolute;
    background-color: #c4b598;
}

.dropdown-menu li {
    display: block;
    min-width: 200px;
}

/* Submenu Links */

.submenu-link {
    color: #ffffff;
    display: block;
    font-size: 15px;
    font-family: 'PT Sans', sans-serif;
    padding-left: 30px;
    padding-right: 15px;
    width: 200px;
}

.submenu-link:hover {
    text-decoration: none;
    color: #383838;
}

.account-dropdown-menu {
    position: absolute;
    right: 40px;
    background-color: #ffffff;
    display: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);


}

.displayAccountDropdown {
    display: flex;
    flex-direction: column;
}

.accountLink {
    color: #383838;
    display: block;
    font-size: 15px;
    font-family: 'PT Sans', sans-serif;
    padding: 5px 20px 5px 20px;
    width: 200px;
    text-align: right;
}

.accountLink:first-child {
    padding-top: 15px;
}

.accountLink:last-child {
    padding-bottom: 15px;
}

.accountLink:first-child:hover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accountLink:last-child:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.accountLink:hover {
    text-decoration: none;
    background-color: #c4b598;
    color: #ffffff;
    cursor: pointer;
}
