.account-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    width: 100%;
}

.account-input-container {
    width: 100%;
}

.account-input, #account_province {
    width: calc(100% - 76px);
}

.pencilBtn {
    margin-right: 0;
}

.pencilBtn:hover {
    background-color: #4c5a74;
}

.material-closebtn {
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.cancelEdit {
    padding: 1px;
    color: #ffffff;
    font-size: 17px;
    padding-bottom: 0;
}