/* --------- */
/* Padding */
.footerContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #161616;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.termsContainer {
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: #2A2E31;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ftr-logo {
    padding-bottom: 15px;
}

.ftr-cta {
    padding-bottom: 20px;
}

.sitemapList {
    padding-left: 40px;
}

/* Mobile-first */
.terms-p {
    padding-bottom: 40px;
}

.ftr-sitemap {
    padding-top: 40px;
}

/* --------- */
/* Font Rules */
.footerContainer p, .footerContainer a, .ftr-sitemap h4 {
    color: #E9E9E9;
}

.termsContainer p, .termsContainer a {
    color: #ffffff;
}

.ftr-cta {
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    line-height: 26px;
}

.terms-p {
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    line-height: 25.5px;
}

.terms-p span {
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    line-height: 26px;
}

.terms-legal, .terms-legal a {
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    line-height: 26px;
}

/* Sitemap */
.ftr-sitemap h4 {
    font-family: 'PT Sans', sans-serif;
    font-size: 21px;
    font-weight: bold;
    line-height: 31.5px;
}

.sitemapList li {
    font-family: 'Oxygen', sans-serif;
    line-height: 26px;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}

/* --------- */
/* CTA and Socials */

.ftr-icon-container {
    display: flex;
    align-items: center;
}

.instagramFooter {
    width: 40px;
    margin-right: 10px;
}

.youtubeFooter {
    width: 50px;
    margin-right: 6px;
}

.linkedinFooter {
    width: 45px;
    margin-right: 10px;
}

.ftr-cta-link button {
    color: #202020;
    font-size: 14px;
    line-height: 26px;
    border-radius: 50px;
    padding: 10px 40px;
    margin-bottom: 10px;
}

.ftr-cta-link button:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: #4c5a74;
}

@media only screen and (min-width: 500px) {
    .sitemapList {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}

@media only screen and (min-width: 640px) {
    .sitemapList {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }
}

@media only screen and (min-width: 770px) {
    .sitemapList {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    .sitemapList li {
        font-size: 14px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    /* Row, Even Column Width */
    .footerContainer {
        flex-direction: row;
        padding-top: 0;
        align-items: flex-start; /* Aligned vertically at the top */
    }
    
    .termsContainer {
        flex-direction: row;
        align-items: flex-start;
    }
    
    .terms-p, .terms-legal, .ftr-socials, .ftr-sitemap {
        width: 50%;
    }

    .ftr-socials, .ftr-sitemap {
        padding-top: 30px;
    }

    /* Gutter */
    .ftr-socials, .terms-p {
        padding-right: 25px;
    }

    .ftr-sitemap, .terms-legal {
        padding-left: 25px;
    }

    /* Icon Sizing */
    .instagramFooter {
        width: 36px;
    }
    
    .youtubeFooter {
        width: 36px;
    }
    
    .linkedinFooter {
        width: 36px;
    }

    .ftr-cta-link button {
        padding: 10px 30px;
    }
}

@media only screen and (min-width: 880px) {
    .ftr-socials, .ftr-sitemap {
        padding-top: 30px;
    }

    /* Icon Sizing */
    .instagramFooter {
        width: 40px;
    }

    .youtubeFooter {
        width: 50px;
    }

    .linkedinFooter {
        width: 45px;
    }

    .ftr-cta-link button {
        padding: 10px 40px;
    }
}

@media only screen and (min-width: 1400px) {
    .sitemapList {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }
}