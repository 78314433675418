/* Positioning */
.desktop-logo {
    padding: 15px 0 9px 0;
}

#desktopNavContainer {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#desktopMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#desktopMenu li {
    display: inline;
}

/* login and register buttons */
.fa-user-plus, .fa-sign-in, .fa-circle-user {
    color: #383838;
}

.fa-sign-in:hover, .fa-user-plus:hover, .fa-circle-user:hover {
    cursor: pointer;
    color: #4c5a74;
}

.fa-user-plus {
    font-size: 24px;
    padding: 0 15px 0 8px;
}

.fa-sign-in {
    font-size: 30px;
}

.fa-circle-user {
    margin: 0 0 0 10px;
    font-size: 50px;
}


/* Submenu Links */
.submenu-link {
    color: #ffffff;
}

.submenu-link:hover {
    color: #383838;
}

